export default [
  {
    key: 'email',
    label: 'field.email',
    placeholder: 'placeholder.email',
    rules: 'required|email|max:50',
    type: 'text',
    cols: 12,
  },
  {
    key: 'password',
    label: 'field.password',
    placeholder: 'placeholder.password',
    rules: 'required',
    type: 'password',
    cols: 12,
  },
]
