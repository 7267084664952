<template>
  <!-- AUTH V1 -->
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <logo />
          <h3 class="brand-text text-primary ml-1">Vattanac Properties Ltd.</h3>
        </b-link>

        <b-card-title class="mb-1"> Welcome to {{ appName }}! 👋 </b-card-title>
        <b-card-text class="mb-2">
          Please sign-in to your account and start managing order
        </b-card-text>

        <validation-observer ref="loginForm" #default="{ invalid }">
          <b-form class="auth-login-form mt-2" @submit.prevent="validateForm">
            <n-input :fields="fields" v-model="data"> </n-input>

            <n-button-loading
              type="submit"
              variant="primary"
              :loading="loading"
              :disabled="invalid"
              block
            >
              {{ $t("button.login") }}
            </n-button-loading>
          </b-form>
        </validation-observer>
      </b-card>
    </div>
  </div>

  <!-- Auth V2 -->
  <!-- <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-link class="brand-logo">
        <logo />
        <h2 class="brand-text text-primary ml-1">{{ appName }}</h2>
      </b-link>
      <b-navbar-nav class="nav align-items-center ml-auto absolute-locale">
        <locale></locale>
      </b-navbar-nav>

      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>

      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            {{ $t("breadcrumb.login") }}
          </b-card-title>

          <validation-observer ref="loginForm" #default="{ invalid }">
            <b-form class="auth-login-form mt-2" @submit.prevent="validateForm">
              <n-input :fields="fields" v-model="data"> </n-input>

              <n-button-loading
                type="submit"
                variant="primary"
                :loading="loading"
                :disabled="invalid"
                block
              >
                {{ $t("button.login") }}
              </n-button-loading>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
    </b-row>
  </div> -->
</template>

<script>
/* eslint-disable global-require */
import Logo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BCard,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BNavbarNav,
} from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";
import { initialAbility } from "@/libs/acl/config";
import store from "@/store/index";
import NPasswordInput from "@/components/NPasswordInput";
import NInput from "@/components/NInput";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import FormInput from "./formInput";
import { $themeConfig } from "@themeConfig";
import NButtonLoading from "@/components/NButtonLoading";
import Locale from "@/layouts/components/app-navbar/Locale.vue";
import { mapAbility } from "@/libs/helper";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BCard,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BNavbarNav,
    Logo,
    Locale,
    NPasswordInput,
    NInput,
    NButtonLoading,
  },
  data() {
    return {
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      // validation rulesimport store from '@/store/index'
      data: {
        email: "",
        password: "",
      },
      loading: false,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    validateForm() {
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          this.submit();
        }
      });
    },
    submit() {
      this.loading = true;
      useJwt
        .login({
          ...this.data,
        })
        .then((response) => {
          const data = response.data.data;
          const userData = data.user;
          useJwt.setToken(data.accessToken);
          useJwt.setRefreshToken(data.refreshToken);
          localStorage.setItem("userData", JSON.stringify(userData));
          this.$store.commit("profile/UPDATE_PROFILE", userData);

          let newAbility = [...initialAbility];
          if (userData.ability) {
            newAbility = mapAbility(userData, newAbility, initialAbility);
          }
          this.$ability.update(newAbility);
          this.$router
            .replace({
              name: getHomeRouteForLoggedInUser(
                "admin",
                this.$route.query.redirect,
                newAbility
              ),
            })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: this.$t("general.welcomeUser", {
                    name: this.data.firstName,
                  }),
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: this.$t("alert.successLoggedIn"),
                },
              });
            });
        })
        .catch((error) => {
          this.loading = false;
          if (error.response?.status === 422) {
            this.$refs.loginForm.setErrors(error.response.data.validate);
          }
        });
    },
  },
  setup() {
    const { appName } = $themeConfig.app;
    const fields = FormInput;
    return {
      appName,
      fields,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
